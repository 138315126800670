@import "~@lor/core-web-components/scss/variables";

.wallboard {
    .app-links {
        position: absolute;
        right: 5px;
        top: 5px;
        height: calc(100% - 10px);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        a {
            color: var(--bs-text-color);
        }
    }

    .card {
        margin-top: 0;
        min-height: 92px;
        border: none;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-family: $primary-fonts;
        height: 100%;
    }

    .card-colour {
        min-width: 3px;
        margin: 4px 0 4px 4px;
        align-self: stretch;
        border-radius: 4px;
    }

    .card-title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 20px;

        h3 {
            line-height: 1.2;
            margin-bottom: 0;
        }
    }

    .card-subtitle {
        padding: 10px 0;
    }

    .health {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        top: 25px;
        right: 25px;

        @each $name, $value in $theme-colors {
            &.#{$name} {
                background-color: #{$value};
            }
        }
    }
}

.status-icon {
    padding-right: 5px;
}
