.get-token {
    .token {
        word-break: break-all;
    }

    h2 {
        color: #FFF;
    }

    .option {
        background-color: transparent;
        color: #4d4d4d;
        cursor: pointer;
        display: block;
        font-size: inherit;
        padding: 8px 12px;
        width: 100%;

        &:hover {
            background-color: #f2f2f2;
            color: #4d4d4d;
            cursor: pointer;
            display: block;
            font-size: inherit;
            padding: 8px 12px;
            width: 100%;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0,0,0,0);
            box-sizing: border-box;
        }
    }

    div[class*="-placeholder"] {
        color: var(--cool-grey-5);
        font-style: italic;
    }
}
